module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-i18n-l10n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"en","siteUrl":"http://devbridgefoundation.org","adjustPath":true,"locales":[{"locale":"en","prefix":"en","slugs":{},"messages":{"readMore":"Read more","showMore":"Show more","showLess":"Show less","linkAllPrograms":"All programs and investments","linkContactUs":"Contact us","fieldName":"Your name:","fieldEmail":"Your email:","fieldMessage":"Your message:","submitInquiry":"Submit inquiry","inquirySubmitting":"Submitting..","thankYouTitle":"Thank You!","thankYouMessage":"Your message has been received, <br />we will contact you shortly.","contactTitle":"Interested in collaboration opportunities?","contactMessage":"Fill out the form and one of our team members will contact you shortly.","titleFocus":"Areas of Focus","titleAllPrograms":"Programs and Investments","serverError":"Unable to submit form. Please try again later.","fieldErrorRequired":"Required field.","fieldErrorEmail":"Email must be a valid email."}},{"locale":"lt","prefix":"lt","slugs":{},"messages":{"readMore":"Skaityti daugiau","showMore":"Daugiau","showLess":"Mažiau","linkAllPrograms":"Visos programos","linkContactUs":"Susisiekti","fieldName":"Vardas:","fieldEmail":"El.paštas:","fieldMessage":"Žinutė:","submitInquiry":"Siųsti užklausą","inquirySubmitting":"Siunčiame..","thankYouTitle":"Ačiū, žinutė išsiųsta","thankYouMessage":"Mes su jumis susisieksime artimiausiu metu.","contactTitle":"Domina bendradarbiavimo galimybės?","contactMessage":"Užpildykite formą ir vienas iš mūsų komandos narių netrukus su jumis susisieks.","titleFocus":"Prioritetinė sritis","titleAllPrograms":"Programos ir investicijos","serverError":"Serverio klaida. Žinutė nebuvo išsiųsta.","fieldErrorRequired":"Privalomas laukas.","fieldErrorEmail":"Neteisingai įvestas el. paštas."}}],"trailingSlash":"always"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6001cbdc773ae8b01391cc455402214f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"http://devbridgefoundation.org","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K8G6Z5BQ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
